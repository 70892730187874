/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background-color: var(--ion-color-white);
}

body {
  font-family: "Inter", "Roboto", "Helvetica Neue", "sans";
  overflow: hidden;
  /* padding-bottom: env(safe-area-inset-bottom) !important; */

  --ion-safe-area-top: env(safe-area-inset-top);
  --ion-safe-area-bottom: env(safe-area-inset-bottom);
}

.ios .page-header {
  padding-top: env(safe-area-inset-top);
}

.safe-area-top-inset {
  padding-top: env(safe-area-inset-top);
}

.safe-area-bottom-inset {
  padding-bottom: env(safe-area-inset-bottom);
}

.ios ion-tab-bar,
.ios .card-modal {
  padding-bottom: env(safe-area-inset-bottom);
}

.ion-modal-fullscreen {
  --border-radius: 0;
  --width: 100vw;
  --height: 100vh;
}

ion-content {
  --background: transparent !important;
}

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #118cb6;
  --ion-color-primary-rgb: 17, 140, 182;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #107496;
  --ion-color-primary-tint: #2591bf;

  /** secondary **/
  --ion-color-secondary: #fa6836;
  --ion-color-secondary-rgb: 250, 104, 54;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #bb3305;
  --ion-color-secondary-tint: #ffa53c;

  /** tertiary **/
  --ion-color-tertiary: #14b8a6;
  --ion-color-tertiary-rgb: 45, 212, 191;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #0d9488;
  --ion-color-tertiary-tint: #2dd4bf;

  /** success **/
  --ion-color-success: #22c55e;
  --ion-color-success-rgb: 34, 197, 94;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #16a34a;
  --ion-color-success-tint: #4ade80;

  /** warning **/
  --ion-color-warning: #eab308;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #ca8a04;
  --ion-color-warning-tint: #facc15;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #9d9d9d;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #687787;
  --ion-color-medium-tint: #d3d3d3;

  /** neutral **/
  --ion-color-neutral: #64748b;
  --ion-color-neutral-rgb: 146, 148, 156;
  --ion-color-neutral-contrast: #ffffff;
  --ion-color-neutral-contrast-rgb: 255, 255, 255;
  --ion-color-neutral-shade: #64748b;
  --ion-color-neutral-tint: #94a3b8;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #e5e5e5;
  --ion-color-light-tint: #ffffff;

  /** strength **/
  --ion-color-strength: #ffa53c;
  --ion-color-strength-rgb: 255, 165, 60;
  --ion-color-strength-contrast: #ffffff;
  --ion-color-strength-contrast-rgb: 255, 255, 255;
  --ion-color-strength-shade: #e09135;
  --ion-color-strength-tint: #ffae50;

  /** cardio **/
  --ion-color-cardio: #f97649;
  --ion-color-cardio-rgb: 249, 118, 73;
  --ion-color-cardio-contrast: #ffffff;
  --ion-color-cardio-contrast-rgb: 255, 255, 255;
  --ion-color-cardio-shade: #db6840;
  --ion-color-cardio-tint: #fa845b;

  /** sport **/
  --ion-color-sport: #f97649;
  --ion-color-sport-rgb: 249, 118, 73;
  --ion-color-sport-contrast: #ffffff;
  --ion-color-sport-contrast-rgb: 255, 255, 255;
  --ion-color-sport-shade: #db6840;
  --ion-color-sport-tint: #fa845b;

  /** mobility **/
  --ion-color-mobility: #107496;
  --ion-color-mobility-rgb: 17, 140, 182;
  --ion-color-mobility-contrast: #ffffff;
  --ion-color-mobility-contrast-rgb: 255, 255, 255;
  --ion-color-mobility-shade: #0d5f6e;
  --ion-color-mobility-tint: #2a8ab4;

  /** knowledge **/
  --ion-color-knowledge: #14b8a6;
  --ion-color-knowledge-rgb: 45, 212, 191;
  --ion-color-knowledge-contrast: #ffffff;
  --ion-color-knowledge-contrast-rgb: 255, 255, 255;
  --ion-color-knowledge-shade: #0d9488;
  --ion-color-knowledge-tint: #2dd4bf;

  /** other **/
  --ion-color-other: #64748b;
  --ion-color-other-rgb: 100, 116, 139;
  --ion-color-other-contrast: white;
  --ion-color-other-contrast-rgb: 255, 255, 255;
  --ion-color-other-shade: #475569;
  --ion-color-other-tint: #94a3b8;

  /** breathing **/
  --ion-color-breathing: #e0eafc;
  --ion-color-breathing-rgb: 224, 234, 252;
  --ion-color-breathing-contrast: #005983;
  --ion-color-breathing-contrast-rgb: 0, 89, 131;
  --ion-color-breathing-shade: #c9d2e2;
  --ion-color-breathing-tint: #f6ffff;

  /** primary dark **/
  --ion-color-navy: #005983;
  --ion-color-navy-rgb: 0, 89, 131;
  --ion-color-navy-contrast: #ffffff;
  --ion-color-navy-contrast-rgb: 255, 255, 255;
  --ion-color-navy-shade: #00406a;
  --ion-color-navy-tint: #00739d;

  /** FIRE **/
  --ion-color-fire: #f97649;
  --ion-color-fire-rgb: 249, 118, 73;
  --ion-color-fire-contrast: #ffffff;
  --ion-color-fire-contrast-rgb: 255, 255, 255;
  --ion-color-fire-shade: #db6840;
  --ion-color-fire-tint: #fa845b;

  /** WATER **/
  --ion-color-water: #0ea5e9;
  --ion-color-water-rgb: 14, 165, 233;
  --ion-color-water-contrast: #ffffff;
  --ion-color-water-contrast-rgb: 255, 255, 255;
  --ion-color-water-shade: #0284c7;
  --ion-color-water-tint: #38bdf8;

  /** EARTH **/
  --ion-color-earth: #84cc16;
  --ion-color-earth-rgb: 132, 204, 22;
  --ion-color-earth-contrast: #ffffff;
  --ion-color-earth-contrast-rgb: 255, 255, 255;
  --ion-color-earth-shade: #65a30d;
  --ion-color-earth-tint: #a3e635;

  /** AIR **/
  --ion-color-air: #e0eafc;
  --ion-color-air-rgb: 224, 234, 252;
  --ion-color-air-contrast: #005983;
  --ion-color-air-contrast-rgb: 0, 89, 131;
  --ion-color-air-shade: #cdddfa;
  --ion-color-air-tint: #e5edfc;

  --ion-color-white: white;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #005983;
  --ion-color-white-contrast-rgb: 0, 89, 131;
  --ion-color-white-shade: white;
  --ion-color-white-tint: white;
}

.ion-color-navy {
  --ion-color-base: var(--ion-color-navy);
  --ion-color-base-rgb: var(--ion-color-navy-rgb);
  --ion-color-contrast: var(--ion-color-navy-contrast);
  --ion-color-contrast-rgb: var(--ion-color-navy-contrast-rgb);
  --ion-color-shade: var(--ion-color-navy-shade);
  --ion-color-tint: var(--ion-color-navy-tint);
}

.ion-color-neutral {
  --ion-color-base: var(--ion-color-neutral);
  --ion-color-base-rgb: var(--ion-color-neutral-rgb);
  --ion-color-contrast: var(--ion-color-neutral-contrast);
  --ion-color-contrast-rgb: var(--ion-color-neutral-contrast-rgb);
  --ion-color-shade: var(--ion-color-neutral-shade);
  --ion-color-tint: var(--ion-color-neutral-tint);
}

.ion-color-MOBILITY {
  --ion-color-base: var(--ion-color-mobility);
  --ion-color-base-rgb: var(--ion-color-mobility-rgb);
  --ion-color-contrast: var(--ion-color-mobility-contrast);
  --ion-color-contrast-rgb: var(--ion-color-mobility-contrast-rgb);
  --ion-color-shade: var(--ion-color-mobility-shade);
  --ion-color-tint: var(--ion-color-mobility-tint);
}

.ion-color-CARDIO {
  --ion-color-base: var(--ion-color-cardio);
  --ion-color-base-rgb: var(--ion-color-cardio-rgb);
  --ion-color-contrast: var(--ion-color-cardio-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cardio-contrast-rgb);
  --ion-color-shade: var(--ion-color-cardio-shade);
  --ion-color-tint: var(--ion-color-cardio-tint);
}

.ion-color-STRENGTH {
  --ion-color-base: var(--ion-color-strength);
  --ion-color-base-rgb: var(--ion-color-strength-rgb);
  --ion-color-contrast: var(--ion-color-strength-contrast);
  --ion-color-contrast-rgb: var(--ion-color-strength-contrast-rgb);
  --ion-color-shade: var(--ion-color-strength-shade);
  --ion-color-tint: var(--ion-color-strength-tint);
}

.ion-color-SPORT {
  --ion-color-base: var(--ion-color-sport);
  --ion-color-base-rgb: var(--ion-color-sport-rgb);
  --ion-color-contrast: var(--ion-color-sport-contrast);
  --ion-color-contrast-rgb: var(--ion-color-sport-contrast-rgb);
  --ion-color-shade: var(--ion-color-sport-shade);
  --ion-color-tint: var(--ion-color-sport-tint);
}

.ion-color-OTHER {
  --ion-color-base: var(--ion-color-other);
  --ion-color-base-rgb: var(--ion-color-other-rgb);
  --ion-color-contrast: var(--ion-color-other-contrast);
  --ion-color-contrast-rgb: var(--ion-color-other-contrast-rgb);
  --ion-color-shade: var(--ion-color-other-shade);
  --ion-color-tint: var(--ion-color-other-tint);
}

.ion-color-BREATHING {
  --ion-color-base: var(--ion-color-breathing);
  --ion-color-base-rgb: var(--ion-color-breathing-rgb);
  --ion-color-contrast: var(--ion-color-breathing-contrast);
  --ion-color-contrast-rgb: var(--ion-color-breathing-contrast-rgb);
  --ion-color-shade: var(--ion-color-breathing-shade);
  --ion-color-tint: var(--ion-color-breathing-tint);
}

.ion-color-KNOWLEDGE {
  --ion-color-base: var(--ion-color-knowledge);
  --ion-color-base-rgb: var(--ion-color-knowledge-rgb);
  --ion-color-contrast: var(--ion-color-knowledge-contrast);
  --ion-color-contrast-rgb: var(--ion-color-knowledge-contrast-rgb);
  --ion-color-shade: var(--ion-color-knowledge-shade);
  --ion-color-tint: var(--ion-color-knowledge-tint);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-FIRE {
  --ion-color-base: var(--ion-color-fire);
  --ion-color-base-rgb: var(--ion-color-fire-rgb);
  --ion-color-contrast: var(--ion-color-fire-contrast);
  --ion-color-contrast-rgb: var(--ion-color-fire-contrast-rgb);
  --ion-color-shade: var(--ion-color-fire-shade);
  --ion-color-tint: var(--ion-color-fire-tint);
}

.ion-color-WATER {
  --ion-color-base: var(--ion-color-water);
  --ion-color-base-rgb: var(--ion-color-water-rgb);
  --ion-color-contrast: var(--ion-color-water-contrast);
  --ion-color-contrast-rgb: var(--ion-color-water-contrast-rgb);
  --ion-color-shade: var(--ion-color-water-shade);
  --ion-color-tint: var(--ion-color-water-tint);
}

.ion-color-EARTH {
  --ion-color-base: var(--ion-color-earth);
  --ion-color-base-rgb: var(--ion-color-earth-rgb);
  --ion-color-contrast: var(--ion-color-earth-contrast);
  --ion-color-contrast-rgb: var(--ion-color-earth-contrast-rgb);
  --ion-color-shade: var(--ion-color-earth-shade);
  --ion-color-tint: var(--ion-color-earth-tint);
}

.ion-color-AIR {
  --ion-color-base: var(--ion-color-air);
  --ion-color-base-rgb: var(--ion-color-air-rgb);
  --ion-color-contrast: var(--ion-color-air-contrast);
  --ion-color-contrast-rgb: var(--ion-color-air-contrast-rgb);
  --ion-color-shade: var(--ion-color-air-shade);
  --ion-color-tint: var(--ion-color-air-tint);
}

.ion-tall-toolbar {
  --min-height: 64px;
}

ion-item {
  --background: "transparent";
  --padding-bottom: 0;
  --padding-top: 0;
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-start: 0;
  --inner-padding-end: 0;
  --inner-padding-bottom: 0;
  --inner-padding-top: 0;
  --border-radius: 3px;
}

ion-label .label-floating {
  margin-top: 4px;
}

ion-input {
  --background: "transparent";
  --padding-start: 8px;
  --padding-left: 8px;
}

ion-title {
  --color: "inherit";
}

ion-range {
  --bar-background: var(--ion-color-light);
  --bar-background-active: #687787;
  --bar-border-radius: 8px;
  --bar-height: 16px;
  --height: 16px;
  --knob-size: 32px;
  --knob-background: #687787;
}

/* .native-input.sc-ion-input-ios {
  --padding-start: 0.5rem;
} */

.woot-widget-bubble {
  bottom: 72px !important;
}

ion-button {
  --border-radius: 2px !important;
}

ion-button,
.button-outline {
  --border-width: 2px !important;
}

span {
  letter-spacing: -0.025em;
}

ion-tab-bar {
  --background: var(--ion-color-white) !important;
}

ion-searchbar {
  --icon-color: var(--ion-color-primary) !important;
}

ion-checkbox {
  --border-radius: 8px !important;
  --background: rgba(255, 255, 255, 0.3) !important;
  --background-checked: rgba(0, 0, 0, 0.25) !important;
  --checkmark-color: rgba(255, 255, 255, 1) !important;
}

.button-native,
[type="submit"] {
  -webkit-appearance: button-bevel !important;
}

.button-outline {
  background: transparent;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  /* body {
  --ion-color-primary: #118CB6;
  --ion-color-primary-rgb: 17, 140, 182;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #107496;
  --ion-color-primary-tint: #2591BF;

  --ion-color-secondary: #FA6836;
  --ion-color-secondary-rgb: 250, 104, 54;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #BB3305;
  --ion-color-secondary-tint: #FFA53C;

  --ion-color-tertiary: #74EFDB;
  --ion-color-tertiary-rgb: 116,239,219;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #19DABA;
  --ion-color-tertiary-tint: #ABF5E9;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  
    --ion-color-medium: #9D9D9D;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #687787;
    --ion-color-medium-tint: #D3D3D3;
  
    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #E5E5E5;
    --ion-color-dark-tint: #ffffff;
  } */

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  /* .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  } */

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  /* .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  } */
}

input:disabled,
textarea:disabled,
input:disabled::placeholder,
textarea:disabled::placeholder {
  -webkit-text-fill-color: currentcolor !important; /* 1. sets text fill to current `color` for safari */
  opacity: 1 !important; /* 2. correct opacity on iOS */
}
